import React from "react";

const KeyboardArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
    >
      <path
        d="M10.59 0.589966L6 5.16997L1.41 0.589966L0 1.99997L6 7.99997L12 1.99997L10.59 0.589966Z"
        fill="white"
        strokeWidth="0.1"
      />
    </svg>
  );
};

export default KeyboardArrowDown;
