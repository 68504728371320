import styled from "@emotion/styled";

import { ButtonSolidToOutline } from "src/styles/buttons";
import * as colors from "src/styles/constants/colors";
import * as typography from "src/styles/constants/typography";
import * as spacing from "src/styles/constants/spacing";
import * as radius from "src/styles/constants/radius";

const NewRoom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${radius.medium};
  gap: ${spacing.space_xs};
  overflow: hidden;
`;

const Button = styled(ButtonSolidToOutline)`
  font-weight: ${typography.semiBold};
  border-radius: 0;
  padding: 2px 8px;
  letter-spacing: 1.35px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  text-transform: none;
  line-height: 20px;
  > svg {
    margin-top: 1px;
  }
  gap: 5px;

  &:hover,
  :focus {
    background-color: ${colors.lightBlue500};
    color: #fff;
    > svg > path {
      transition: 0.3s ease-in-out;
    }
  }

  > svg > path {
    stroke: white;
  }
`;

const DropDownButton = styled(Button)`
  padding: 2px;
  width: 26px;
`;

export { NewRoom, Button, DropDownButton };
