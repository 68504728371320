// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import Name from "src/containers/workflow/Name";
import Search from "src/containers/workflow/Search";
import Icon from "src/components/InlineSVG";
import {
  Workflow as StyledWorkflow,
  RightSubMenu as SubMenu
} from "src/styles/chatroom";
import { iconSize, colors } from "src/styles/constants";

type Props = {
  mostUsedWorkflows: Array<number>,
  handleSelect: Function
};

const Workflow = ({ mostUsedWorkflows, handleSelect }: Props) => {
  return (
    <ul data-cy="processTemplateOptions">
      <li>
        <h4>{i18n.t(k.START_PROCESS)}</h4>
        {R.map(
          workflow => (
            <li
              data-cy="mostUsedTemplates"
              key={workflow}
              role="button"
              tabIndex="0"
              onClick={() => {
                handleSelect("workflow", workflow);
              }}
              onKeyPress={() => handleSelect("workflow", workflow)}
            >
              <h5>
                <Icon
                  icon="workflow"
                  size={iconSize.large}
                  color={colors.black}
                />

                <span>
                  <Name id={workflow} />
                </span>
              </h5>
            </li>
          ),

          mostUsedWorkflows
        )}
      </li>
      <li>
        <h5>
          <Icon icon="workflow" size={iconSize.large} color={colors.black} />
          <span data-cy="otherProcessTemplates">{i18n.t(k.OTHER)}</span>
          <Icon icon="arrowRight" size={iconSize.small} color={colors.black} />
          <SubMenu>
            <StyledWorkflow>
              <Search
                exclude={mostUsedWorkflows}
                handleSelect={id => handleSelect("workflow", id)}
                handleConversation={handleSelect}
              />
            </StyledWorkflow>
          </SubMenu>
        </h5>
      </li>
    </ul>
  );
};

export default Workflow;
